<div class="bg-footer text-white h-100 bottom-0">
  <footer class="md:footer p-10 mb-0 place px-44 items-center justify-around">
    <aside class="pl-10">
      <p class="uppercase">conad società cooperativa</p>
      <p class="opacity-75">
        Via Michelino, 59 | 40127<br />
        <span class="uppercase">bologna</span><br />
        CF e Registro imprese di Bologna 00865960157<br />
        P.IVA 03320960374
      </p>
    </aside>
    <nav>
      <h6 class="footer-title">Link utili</h6>
      @if(loggedMenu() && user() && user()!.role === 'ASD') {
      <a
        class="link link-hover"
        [routerLink]="['./asd/carica-buoni-sport']"
        >Dona i Buoni Sport</a
      >
      } @else {
        <a
        class="link link-hover"
        [routerLink]="['./carica-buoni-sport']"
        >Dona i Buoni Sport</a
      >

      }
      <a
        class="link link-hover"
        [routerLink]="['./marketing-kit']"
        >Marketing Kit</a
      >
      
    </nav>
    <nav>
      <h6 class="footer-title">Informazioni</h6>
      <a class="link link-hover"
      [routerLink]="['/privacy-policy']"
        routerLinkActive="router-link-active">Informativa privacy</a>
      <a
        class="link link-hover"
        [routerLink]="['/modalita-partecipazione']"
        routerLinkActive="router-link-active"
        >Modalità di partecipazione</a
      >
      <a 
        class="link link-hover"
        [routerLink]="['/cookie-policy']"
        routerLinkActive="router-link-active">Cookie policy</a>
      <a
        class="link link-hover"
        [routerLink]="['/credits']"
        routerLinkActive="router-link-active"
        >Credits</a
      >
    </nav>
    <nav>
      <h6 class="footer-title">Il mondo Conad</h6>
      <a href="https://www.conad.it/" target="_blank" class="link link-hover"
        >conad.it</a
      >
      <a
        class="link link-hover"
        href="https://chisiamo.conad.it/"
        target="_blank"
        >Istituzionale</a
      >
    </nav>
  </footer>
  <hr class="text-white py-5 m-auto w-3/4" />
  <div class="grid grid-cols-3 container m-auto gap-4">
    <div>
      <div class="flex gap-2">
        <span class="opacity-75">Seguici sui social</span>
        <a href="https://www.facebook.com/Conad" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="fill-current"
          >
            <path
              d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"
            ></path>
          </svg>
        </a>
        <a href="https://www.instagram.com/conad/" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24px"
            height="24px"
            viewBox="0 0 24 24"
            class="fill-current"
          >
            <path
              d="M12 0C8.74 0 8.333.015 7.053.072 5.775.132 4.905.333 4.14.63c-.789.306-1.459.717-2.126 1.384S.935 3.35.63 4.14C.333 4.905.131 5.775.072 7.053.012 8.333 0 8.74 0 12s.015 3.667.072 4.947c.06 1.277.261 2.148.558 2.913.306.788.717 1.459 1.384 2.126.667.666 1.336 1.079 2.126 1.384.766.296 1.636.499 2.913.558C8.333 23.988 8.74 24 12 24s3.667-.015 4.947-.072c1.277-.06 2.148-.262 2.913-.558.788-.306 1.459-.718 2.126-1.384.666-.667 1.079-1.335 1.384-2.126.296-.765.499-1.636.558-2.913.06-1.28.072-1.687.072-4.947s-.015-3.667-.072-4.947c-.06-1.277-.262-2.149-.558-2.913-.306-.789-.718-1.459-1.384-2.126C21.319 1.347 20.651.935 19.86.63c-.765-.297-1.636-.499-2.913-.558C15.667.012 15.26 0 12 0zm0 2.16c3.203 0 3.585.016 4.85.071 1.17.055 1.805.249 2.227.415.562.217.96.477 1.382.896.419.42.679.819.896 1.381.164.422.36 1.057.413 2.227.057 1.266.07 1.646.07 4.85s-.015 3.585-.074 4.85c-.061 1.17-.256 1.805-.421 2.227-.224.562-.479.96-.899 1.382-.419.419-.824.679-1.38.896-.42.164-1.065.36-2.235.413-1.274.057-1.649.07-4.859.07-3.211 0-3.586-.015-4.859-.074-1.171-.061-1.816-.256-2.236-.421-.569-.224-.96-.479-1.379-.899-.421-.419-.69-.824-.9-1.38-.165-.42-.359-1.065-.42-2.235-.045-1.26-.061-1.649-.061-4.844 0-3.196.016-3.586.061-4.861.061-1.17.255-1.814.42-2.234.21-.57.479-.96.9-1.381.419-.419.81-.689 1.379-.898.42-.166 1.051-.361 2.221-.421 1.275-.045 1.65-.06 4.859-.06l.045.03zm0 3.678c-3.405 0-6.162 2.76-6.162 6.162 0 3.405 2.76 6.162 6.162 6.162 3.405 0 6.162-2.76 6.162-6.162 0-3.405-2.76-6.162-6.162-6.162zM12 16c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm7.846-10.405c0 .795-.646 1.44-1.44 1.44-.795 0-1.44-.646-1.44-1.44 0-.794.646-1.439 1.44-1.439.793-.001 1.44.645 1.44 1.439z"
            />
          </svg>
        </a>
        <a
          href="https://www.youtube.com/channel/UC0pjS6lNq1yvANCAMEpFnPQ"
          target="_blank"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="fill-current"
          >
            <path
              d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"
            ></path>
          </svg>
        </a>
        <a href="https://twitter.com/Conad" target="_blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            class="fill-current"
          >
            <path
              d="M13.795 10.533 20.68 2h-3.073l-5.255 6.517L7.69 2H1l7.806 10.91L1.47 22h3.074l5.705-7.07L15.31 22H22l-8.205-11.467Zm-2.38 2.95L9.97 11.464 4.36 3.627h2.31l4.528 6.317 1.443 2.02 6.018 8.409h-2.31l-4.934-6.89Z"
            ></path>
          </svg>
        </a>
      </div>
    </div>
    <div class="flex flex-row pb-3 text-grey-600">
      @if(platform() === 'web') {
        <div>
          <span class="opacity-75">Scarica l'app</span>
        </div>
        <div>
          <a href="https://apps.apple.com/app/id6504358822" target="_blank"><img class="px-2" src="./assets/ios.svg" alt="" /></a>
        </div>
        <div>
          <a href="https://play.google.com/store/apps/details?id=it.conad.sport" target="_blank"><img class="px-2" src="./assets/android.svg" alt="" /></a>
        </div>
      }
    </div>
    <div class="opacity-75">Copyright &copy; Conad {{ data | date : "Y" }}</div>
  </div>
</div>
