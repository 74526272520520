<app-loading-spinner></app-loading-spinner>
<div class="">
    <app-menu />
    <div class="flex flex-col min-h-screen max-w-screen-lg mx-3 lg:mx-auto">
        <router-outlet />
    </div>
    <div class=" hidden sm:block">
        <app-footer />
    </div>
</div>

