<div class=" bg-orange">
    <div class="max-w-screen-lg mx-3 lg:mx-auto py-3 sm:flex flex-row text-white md:visible hidden  justify-end">
        <div class="basis-1/2 sm:basis-1/2 md:basis-2/4"></div>
        <div class="basis-1/2 sm:basis-1/2 md:basis-1/4">
            <ul class="flex flex-row text-sm md:text-base *:text-right justify-end">
                <li class="px-3">
                    <a href="https://chisiamo.conad.it/chi-siamo" target="_blank" class="">Chi siamo</a>
                </li>
                <li class="px-3">
                    <a [routerLink]="['/faq']" routerLinkActive="router-link-active" >FAQ</a>
                </li>
                <li class="pl-3">
                    <a href="#" [routerLink]="['/contatti']" >Contatti</a>
                </li>
            </ul>
        </div>
    </div>
    
</div>