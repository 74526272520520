<div
  class="max-w-screen-lg mx-3 lg:mx-auto items-center min-h-16 bg-base-100 sm:flex md:pl-10 hidden">
  <div class="basis-2/12">
    <a class="" routerLink="home">
      <img
        class="w-16 md:w-32 lg:w-32 absolute top-0"
        src="./assets/logo-sosteniamo-il-futuro-dello-sport.png"
        alt="Sosteniamo il Futuro dello sport - logo"
      />
    </a>
  </div>
  <div class="basis-7/12">
    <ul class="menu menu-horizontal text-base px-1">
      <li>
        <a
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          routerLinkActive="underline"
          >Home</a
        >
      </li>
      <li>
        <a [routerLink]="['/progetto']" routerLinkActive="underline"
          >Progetto</a
        >
      </li>
      <li>
        <a [routerLink]="['/catalogo-premi']" routerLinkActive="underline"
          >Catalogo premi</a
        >
      </li>
      <li>
        <a [routerLink]="['/tour']" routerLinkActive="underline"
          >Tour</a
        >
      </li>
      <li>
        <a [routerLink]="['/marketing-kit']" routerLinkActive="underline">Marketing Kit</a>
      </li>
    </ul>
  </div>
  <div class="basis-3/12 text-right">

    @if(canOrder && loggedMenu() && userRole() == 'ASD') {
      <div class="grid grid-cols-2">
        <div>
          <a class="text-neutral" routerLinkActive="active-link" [routerLink]="['./asd/carrello']">
            <span class="relative inline-flex  text-sm font-medium text-center text-black">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" [attr.d]='cartIcon' />
              </svg>
              <span class="sr-only">Quantità prodotti nel carrello</span>
              <div class="absolute inline-flex items-center justify-center w-4 h-4 text-[10px] font-bold text-white bg-orange border-2 border-orange rounded-full -top-2 -end-2">{{itemsInCartQnt()}}</div>
            </span>
        
          </a>
        </div>
        <div>
          <a class="link" (click)="goToReservedArea()">Area riservata</a>
        </div>
      </div>
      
    } @else {
      <a class="link" (click)="goToReservedArea()">Area riservata</a>
    }
    
  </div>
</div>

<div class="grid grid-cols-3 content-center bg-base-100 h-24 sm:hidden border-b-2 border-orange">
  <div class="flex items-center">
    @if(showButton) {
      <a class="pl-2 cursor-pointer" (click)="back()"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
      </svg>
    </a>
    }
    
      
  </div>
  <div>
    <div class="mx-auto pb-2 justify-center flex">
      <a class="" routerLink="home">
        <img
          class="w-24"
          src="./assets/logo-sosteniamo-il-futuro-dello-sport.png"
          alt="Sosteniamo il Futuro dello sport - logo"
        />
      </a>
    </div>
  </div>
</div>


