import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter, tap, catchError } from 'rxjs';
import { Role } from '../../../models/user.model';
import { AuthenticationService } from '../../../services/authentication.service';
import { CartService } from '../../../orders/services/cart.service';
import { environment as env } from '../../../../environments/environment';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './nav-bar.component.html',
  styles: ``
})
export class NavBarComponent implements OnInit{
  location = inject(Location);
  router = inject(Router);
  authenticationService = inject(AuthenticationService);  
  cartService = inject(CartService);
  loggedMenu = computed(() => this.authenticationService.menuChanged())
  userRole = computed(() => this.authenticationService.userRole());
  cartIcon = "M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 0 0-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 0 0-16.536-1.84M7.5 14.25 5.106 5.272M6 20.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm12.75 0a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z";
  
  itemsInCartQnt = computed(() => this.cartService.itemsInCartQnt());
  showButton: boolean = false;
  canOrder = env.orders;

  async ngOnInit(): Promise<void> {
    await this.authenticationService.getUser().then((loggedUserRes) => {
      if (loggedUserRes()) {
        return this.authenticationService.setUserRole(loggedUserRes().role);
      }
      return undefined;
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      // Replace '/specific-route' with the route you want to exclude
      if(event instanceof NavigationEnd) {
       this.showButton = (event.url !== '/' && event.url !== '/home');
      }
    });
    if (this.userRole() === Role.ASD) {
      await this.cartService
        .getCart()
        .pipe(
          tap((cartResponse) => {
            if (!cartResponse) return;
            this.cartService.refreshCart();
          }),
          catchError(async (error) => {
            console.log('Errore ', error);
            return error;
          })
        )
        .subscribe();
    }
  }
  
  back() {    
    if(this.location.isCurrentPathEqualTo('/ricerca-asd') || this.location.isCurrentPathEqualTo('/asd/carica-buoni-sport') ) {
      this.router.navigate(['/home']);
    } else {
      this.location.back();
    }
  }

  goToReservedArea() {
    switch(this.userRole()) {
      case Role.ASD:
        this.router.navigate(['/asd/carica-buoni-sport']);
        break;
      case Role.CONAD:
        this.router.navigate(['/conad/welcome']);
        break;
      case Role.BACKOFFICE:
        this.router.navigate(['/back-office/elenco-asd']);
        break;
      default:
        this.router.navigate(['login']);
        break;
    }
  } 
}
