import { Routes } from '@angular/router';
import { authenticationGuard } from './guard/authentication.guard';
import { accountGuard } from './guard/account.guard';
import { roleGuard } from './guard/role.guard';
import { orderGuard } from './guard/order.guard';
import { Role } from './models/user.model';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadComponent: () => import('./home/home.component'),
  },
  {
    path: 'redirect/mobile',
    loadComponent: () => import('./home/home.component'),
  },
  {
    path: 'login',
    canActivate: [accountGuard],
    loadComponent: () => import('./login/login.component'),
  },
  {
    path: 'conad/welcome',
    loadComponent: () =>
      import('./user-conad/welcome-page/welcome-page.component'),
    canActivate: [authenticationGuard, roleGuard],
    data: {
      roles: [Role.CONAD],
    },
  },
  {
    path: 'asd',
    loadComponent: () => import('./asd/asd.component'),
    canActivate: [authenticationGuard, roleGuard],
    data: {
      roles: [Role.ASD],
    },
    children: [
      {
        path: '',
        redirectTo: 'asd',
        pathMatch: 'full',
      },
      {
        path: 'modifica-asd/:id',
        loadComponent: () => import('./asd/edit-asd/edit-asd.component'),
      },
      {
        path: 'lista-dei-desideri/:id',
        loadComponent: () => import('./asd/wishlist/wishlist.component'),
      },
      {
        path: 'carrello',
        canActivate: [orderGuard],
        loadComponent: () => import('./orders/cart/cart.component'),
      },
      {
        path: 'carica-buoni-sport',
        loadComponent: () => import('./points/add-points/add-points.component'),
      },
      {
        path: 'marketing-kit',
        loadComponent: () =>
          import('./asd/marketing-kit/marketing-kit.component'),
      },
      {
        path: 'carica-codice-invito',
        loadComponent: () => import('./asd/assign-code/assign-code.component')
      },
      {
        path: 'checkout-ordine',
        canActivate: [orderGuard],
        loadComponent: () => import('./orders/checkout/checkout.component'),
      },
      {
        path: 'riassunto-ordine/:orderId',
        canActivate: [orderGuard],
        loadComponent: () => import('./orders/order-summary/order-summary.component'),
      },
      {
        path: 'ordini',
        canActivate: [orderGuard],
        loadComponent: () => import('./orders/orders/orders.component')
      },
      {
        path: 'ordini/:id',
        canActivate: [orderGuard],
        loadComponent: () => import('./orders/order-details/order-details.component')
      },
    ],
  },
  {
    path: 'asd/registrazione/riepilogo',
    loadComponent: () => import('./pages/riepilogo.component'),
  },
  {
    path: 'asd/registrazione/riepilogo/:id/:coupon',
    loadComponent: () => import('./pages/riepilogo.component'),
  },
  {
    path: 'asd/registrazione/conferma',
    loadComponent: () => import('./pages/conferma.component'),
  },
  {
    path: 'asd/registrazione/conferma/:user/:token',
    loadComponent: () => import('./pages/conferma.component'),
  },
  {
    path: 'asd/registrazione',
    loadComponent: () => import('./asd/form-asd/form-asd.component'),
    canActivate: [accountGuard],
    children: [
      {
        path: ':coupon',
        loadComponent: () => import('./asd/form-asd/form-asd.component'),
      },
    ],
  },
  {
    path: 'ricerca-asd',
    loadComponent: () => import('./points/asd-search/asd-search.component'),
  },
  {
    path: 'carica-buoni-sport',
    loadComponent: () => import('./points/add-points/add-points.component'),
  },
  {
    path: 'reset-password',
    loadComponent: () => import('./reset-password/reset-password.component'),
  },
  // backoffice
  {
    path: 'back-office',
    loadComponent: () => import('./back-office/back-office.component'),
    canActivate: [authenticationGuard, roleGuard],
    data: {
      roles: [Role.BACKOFFICE],
    },
    children: [
      {
        path: '',
        redirectTo: 'back-office',
        pathMatch: 'full',
      },
      {
        path: 'elenco-asd', // da visualizzare solo su mobile o da sergio su desktop
        loadComponent: () => import('./back-office/list-asd/list-asd.component'),
      },
      {
        path: 'elenco-asd/:asdId', 
        loadComponent: () => import('./back-office/asd-detail/asd-detail.component'),
      },
      {
        path: 'elenco-asd/:asdId/ordini',
        loadComponent: () => import('./back-office/order-list-asd/order-list-asd.component'),
      },
      {
        path: 'ordini',
        loadComponent: () => import('./back-office/order-list/order-list.component'),
      },
      // {
      //   path: 'elenco-asd/:asdId/ordini/:orderId',
      //   loadComponent: () => import('./back-office/order-detail/order-detail.component'),
      // }
    ],
  },
  // pagine
  {
    path: 'informativa',
    loadComponent: () => import('./pages/tour-information.component'),
  },
  {
    path: 'app-conad-2024',
    loadComponent: () => import('./pages/temporary-page.component'),
  },
  {
    path: 'progetto',
    loadComponent: () => import('./pages/project.component'),
  },
  {
    path: 'faq',
    loadComponent: () => import('./pages/faq.component'),
  },
  {
    path: 'catalogo-premi',
    loadComponent: () =>
      import('./products/products-list/products-list.component'),
  },
  {
    path: 'catalogo-premi/:id',
    loadComponent: () => import('./products/product/product.component'),
  },
  {
    path: 'modalita-partecipazione',
    loadComponent: () => import('./pages/partecipation-method.component'),
  },
  {
    path: 'contatti',
    loadComponent: () => import('./pages/contact.component'),
  },
  {
    path: 'informazioni',
    loadComponent: () => import('./pages/information.component'),
  },
  {
    path: 'credits',
    loadComponent: () => import('./pages/credits.component'),
  },
  {
    path: 'privacy-policy',
    loadComponent: () => import('./pages/privacy-policy.component'),
  },
  {
    path: 'cookie-policy',
    loadComponent: () => import('./pages/cookie-policy.component'),
  },
  {
    path: 'codice-invito',
    loadComponent: () => import('./pages/invitation-code.component'),
  },
  {
    path: 'marketing-kit',
    loadComponent: () => import('./pages/public-marketing-kit.component'),
  },
  {
    path: 'cliente-conad',
    loadComponent: () => import('./pages/conad-client.component'),
  },
  {
    path: 'tour',
    loadComponent: () => import('./pages/tour.component'),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./errors/page-not-found/page-not-found.component'),
  },
];
