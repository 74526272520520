import { Component, OnInit, OnDestroy, inject, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerService } from './service/loading-spinner.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-loading-spinner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss']
})
export default class LoadingSpinnerComponent implements OnInit, OnDestroy {
  loaderService = inject(LoadingSpinnerService);
  cdr = inject(ChangeDetectorRef);
  loadingSubscription: Subscription | undefined;
  loading = false;

  ngOnInit(): void {
    this.loadingSubscription = this.loaderService.getLoading().subscribe(isLoading => {
      this.loading = isLoading;
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
  }
}