import { inject, signal } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { LoggedUser, Role } from '../models/user.model';

export const accountGuard: CanActivateFn = async (route, state) => {
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  let loggedUser = signal<LoggedUser | null>(null);
  if ((await authService.isAuthenticated())) { 
    await authService.getUser().then(loggedUserRes => loggedUser = loggedUserRes);
      if(loggedUser()?.role === Role.ASD) router.navigate(['asd/carica-buoni-sport']);
      else if(loggedUser()?.role === Role.CONAD) router.navigate(['conad/welcome']);
    return false;
  }
    
    return true;
};