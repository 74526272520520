import { ErrorHandler, inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { AppService } from '../services/app.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  appService = inject(AppService);

  async handleError(error: any): Promise<void> {
    console.group('GlobalErrorHandler: ');
    console.log('message: ', error.message);
    console.log('stack: ', error.stack);
    console.groupEnd();
    const chunkFailedMessage = /Loading chunk .+ failed/;
    const moduleFailedMessage = /Failed to load module script: Expected a JavaScript module script but the server responded with a MIME type of "text\/html". Strict MIME type checking is enforced for module scripts per HTML spec./;
    const dynamicImportFailedMessage = /Failed to fetch dynamically imported module: https:\/\/sport\.conad\.it\/chunk-[a-zA-Z0-9]+\.js/;

    const invalidObjectRXJSMessage = "You provided an invalid object where a stream was expected. You can provide an Observable, Promise, ReadableStream, Array, AsyncIterable, or Iterable."
    if (error.message === invalidObjectRXJSMessage) {
      return;
    }

    console.log('GlobalErrorHandler: ', error.message);

    await this.appService.reportError(error);

    if (Capacitor.getPlatform() === 'web' && (chunkFailedMessage.test(error.message) || moduleFailedMessage.test(error.message) || dynamicImportFailedMessage.test(error.message))) {
      await Promise.all((await caches.keys()).map(caches.delete));
      if (!window.location.href.endsWith('#refreshed')) {
        window.location.replace(window.location.href + '#refreshed');
        return;
      }
    }
  }
}