import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

export const roleGuard: CanActivateFn = async (route, state) => {
  const roles = route.data['roles'] as string[];
  const authService = inject(AuthenticationService);
  const router = inject(Router);
  const userRole = await authService.getUserRole();
  if(!authService.isAuthenticated()) {
    router.navigate(['./login']);
    return false;
  }
  if(roles.some(role => role === userRole )) {
    return true
  }
  return false;
};
