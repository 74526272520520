import { Component, NgZone, OnDestroy, OnInit, effect, inject, signal } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { MenuComponent } from './shared/menu/menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { AuthenticationService } from './services/authentication.service';
import { AsdService } from './asd/service/asd.service';
import AddPointsComponent from './points/add-points/add-points.component';
import AsdComponent from './asd/asd.component';
import { Preferences } from '@capacitor/preferences';
import { catchError, tap } from 'rxjs';
import { Dialog } from '@capacitor/dialog';
import LoadingSpinnerComponent from './utils/loading-spinner/loading-spinner.component';
import { AppService } from './services/app.service';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet, 
    MenuComponent, 
    FooterComponent,
    AddPointsComponent, 
    AsdComponent, 
    LoadingSpinnerComponent,
    
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'Sosteniamo il Futuro dello Sport';
  authenticationService = inject(AuthenticationService);
  asdService = inject(AsdService);
  appService = inject(AppService);

  async ngOnInit(): Promise<void> {
    const { value: representativeEditionId } = (await Preferences.get({ key: 'asdId' }));
    if(representativeEditionId)
    this.asdService.getAsd(representativeEditionId).pipe(
      tap(async res => {
        if(!res) return;
        if(res.data[0].points !== undefined) {
          this.asdService.changePoints(res.data[0].points);
        }
      }),
      catchError(error => {
        Dialog.alert({
          title: 'Attenzione',
          message: error.error.error.message
        })
        return error
      })).subscribe();
  }

  constructor(private router: Router, private zone: NgZone) {
    this.initializeApp();
  }
  

  initializeApp() {
    if(Capacitor.getPlatform()) {
      this.appService.setPlatform(Capacitor.getPlatform());
    }
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        let slug: string | undefined;
        let url = new URL(event.url);
        let ssoToken = url.searchParams.get('ssoToken');
        url.searchParams.delete('ssoToken');
        
        let search = url.search === '?' ? '' : url.search;
        if (event.url.includes('sport.conad.it/mobile')) { //
          slug = url.toString().split('sport.conad.it/mobile').pop() + search;
        } else if (event.url.includes('sport.conad.it')) { //
          slug = url.toString().split('sport.conad.it').pop() + search;
        }
        if (ssoToken) {
          this.authenticationService.loginSsoToken(ssoToken).subscribe();
          return;
        }
        if (slug) {
          this.router.navigateByUrl(slug);
        } else {
          this.router.navigateByUrl('/');
        }
      });
    });
  }
  ngOnDestroy(): void {
    this.authenticationService.logout();
  }
}
