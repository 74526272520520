import { DatePipe } from '@angular/common';
import { Component, computed, inject, signal } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { LoggedUser } from '../../models/user.model';
import { AppService } from '../../services/app.service';


@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    DatePipe,
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './footer.component.html',
  styles: ``
})
export class FooterComponent {
  data: Date = new Date();
  user = signal<LoggedUser| null>(null);
  router = inject(Router);
  appService = inject(AppService);
  platform = computed(() => this.appService.platform());
  authenticationService = inject(AuthenticationService);
  loggedMenu = computed(() => this.authenticationService.menuChanged())
  async ngOnInit(): Promise<void> {
    await this.authenticationService.getUser().then(loggedUserRes => this.user = loggedUserRes);
  }
}
