import { AfterViewInit, Component, Input, OnDestroy, OnInit, computed, effect, inject, input, signal } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { AuthenticationService } from '../../../services/authentication.service';
import { LoggedUser } from '../../../models/user.model';
import { environment as env } from '../../../../environments/environment';

@Component({
  selector: 'app-bottom-bar',
  standalone: true,
  imports: [
    RouterLink,
    RouterLinkActive
  ],
  templateUrl: './bottom-bar.component.html',
  styles: ``
})
export class BottomBarComponent implements OnInit, AfterViewInit, OnDestroy {
  authenticationService = inject(AuthenticationService);
  loggedMenu = computed(() => this.authenticationService.menuChanged())
  userRole = computed(() => this.authenticationService.userRole())
  user = signal<LoggedUser| null>(null);
  canOrder = env.orders;

  async ngOnInit(): Promise<void> {
    await this.authenticationService.getUser().then(loggedUserRes => this.user = loggedUserRes);
  }

  constructor() {
    effect(() => {
      this.loggedMenu();
      this.authenticationService.getUser().then(loggedUserRes => this.user = loggedUserRes);
    });
  }
  
  async logout(){
    //this.isLoggedIn.set(false);
    await this.authenticationService.logout();
  }

  router = inject(Router);
  openLink(url: string): void {
    window.open(url, '_blank');
  }
  goToLink(route: string): void {
    if(route === 'logout') { 
      this.logout()
    }
    this.router.navigate([route]);
    const elem = document.activeElement;
    if (elem) {
      elem?.parentElement?.blur();
    }
  }

  share() {
    const textToShare = `🫵 Sostieni le Associazioni e Società Sportive Dilettantistiche donando i Buoni Sport che puoi ottenere partecipando all'iniziativa "Sosteniamo il Futuro dello Sport di Conad"\n\n📅 Dal 24 giugno 2024 al 15 settembre 2024, facendo la spesa nei punti vendita Conad, riceverai in cassa, ogni 15€ di spesa, un Buono Sport che potrai donare alla tua associazione sportiva.\n\nL'iniziativa consentirà all'associazione sportiva di ricevere gratuitamente attrezzature e prodotti sportivi accumulando i Buoni Sport.\n\n✅️ COME DONARE I BUONI SPORT?\n- Tramite App "Sport Conad": scarica l'App, scansiona con la fotocamera il codice a barre posizionato sul retro del Buono Sport oppure inserisci il codice riportato manualmente e seleziona l'associazione sportiva.\n- Tramite sito dell'iniziativa sport.conad.it: inserisci il codice riportato manualmente e seleziona l'associazione sportiva per donare.\n- Puoi portare i Buoni Sport direttamente a mano.\n\nGrazie in anticipo per il tuo contributo!\nPer maggiori informazioni vai su sport.conad.it`;
    window.open(
      "https://api.whatsapp.com/send?text="+encodeURIComponent(textToShare), '_blank'
    )
  }

  ngAfterViewInit(): void {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
  }

  ngOnDestroy(): void {
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
  }

  handleDocumentClick(event: MouseEvent): void {
    const dropdown = document.querySelector('.dropdown');
    if (dropdown && !dropdown.contains(event.target as Node)) {
      dropdown.classList.remove('dropdown-open');
    }
  }

  toggleDropdown(event: Event): void {
    event.stopPropagation();
    const dropdown = document.querySelector('.dropdown');
    if (dropdown) {
      dropdown.classList.toggle('dropdown-open');
    }
  }

}
