import { HttpInterceptorFn } from '@angular/common/http';
import { environment as env } from '../../environments/environment';
export const editionNameInterceptor: HttpInterceptorFn = (req, next) => {
  const editionId = env.edition;
  let newReq = req;
  if(editionId) {
    newReq = req.clone({
      headers: req.headers.set('EditionName', editionId)
    });
    return next(newReq);
  }
  return next(newReq);
};
