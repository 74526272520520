import { Component, Input } from '@angular/core';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { TopBarComponent } from './top-bar/top-bar.component';
import { BottomBarComponent } from './bottom-bar/bottom-bar.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    TopBarComponent,
    NavBarComponent,
    BottomBarComponent
  ],
  template: `
    <app-top-bar />
    <app-nav-bar />
    <app-bottom-bar />
  `,
  styleUrl: './menu.component.scss'
})
export class MenuComponent {
  
}
