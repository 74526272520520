import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import { editionNameInterceptor } from './interceptor/edition-name.interceptor';
import { loadingSpinnerInterceptor } from './interceptor/loading-spinner.interceptor';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import {JwtModule} from "@auth0/angular-jwt";
import { Preferences } from '@capacitor/preferences';
import {AuthenticationService} from "./services/authentication.service";
import { AuthenticationInterceptor} from './interceptor/authentication.interceptor';
import { GlobalErrorHandler } from './interceptor/error-handler';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding(), 
    withInMemoryScrolling({anchorScrolling:'enabled', scrollPositionRestoration: 'top'})),
    provideHttpClient(withInterceptors([editionNameInterceptor, loadingSpinnerInterceptor])),
    {
      provide: APP_INITIALIZER,
      useFactory: initializerFactory,
      multi: true,
      deps: [AuthenticationService]
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    importProvidersFrom([
      JwtModule.forRoot({
        config: {
          tokenGetter: async () => (await Preferences.get({ key: 'token'}).then()).value
        }
      })
    ]),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, 
      useClass: AuthenticationInterceptor, 
      multi: true }

  ]
};

export function initializerFactory(authService: AuthenticationService) {
  return () => authService.refreshToken();
}
