import { CanActivateFn, Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { inject } from '@angular/core';

export const authenticationGuard: CanActivateFn = async (route, state) => {
  const authenticationService = inject(AuthenticationService);
  const router = inject(Router);
  if(!(await authenticationService.isAuthenticated())) {
    router.navigate(['/login']);
    return false;
  }
  return true;
};
